<template>
  <!-- <main-wrapper manage :style="{overflowY:isAnimating?'hidden':'auto'}" :tool="false"> -->
    <div class="v-wrapper tool--none">
      <div class="v-body">
        <template>
          <div class="search-bars">
            <!-- <tool-tip :content="$t('accountManage.add')" placement="top" type="light-tip" :offset="-10">
            <el-button @click="addHandle" v-if="loginInfo.allFuns.includes(5031)" type="primary" plain circle v-ripple>
               <i-icon name="iconxinxiguanli_tianjia_weixuanzhong"></i-icon>
            </el-button>
            </tool-tip>
            <tool-tip :content="$t('accountManage.delete')" placement="top" type="light-tip" :offset="-10">
            <el-button @click="deleteHandle" v-if="loginInfo.allFuns.includes(5033)" type="primary" plain circle v-ripple>
              <i-icon name="iconshanchu"></i-icon>
            </el-button>
            </tool-tip>
            <tool-tip :content="$t('accountManage.export')" placement="top" type="light-tip" :offset="-10">
            <el-button @click="exportHandle" :loading="exportButtonLoading" type="primary" plain circle v-ripple>
               <i-icon name="iconxinxiguanli_daochu_weixuanzhong" v-if="!exportButtonLoading"></i-icon>
            </el-button>
            </tool-tip> -->
            <div class="e-radio"  @click="addHandle" type="primary" v-if="loginInfo.allFuns.includes(5031)">添加</div>
            <div class="e-radio"  @click="deleteHandle"  type="primary" v-if="loginInfo.allFuns.includes(5033)">删除</div>
            <div class="e-radio" @click="exportHandle" v-loading="exportButtonLoading">导出</div>
            
            <search-group style="float:right;" :options="options" @click="click" @input="({select,value,Op}) => {searchName=select;searchText=value;searchOption=Op}" :field="{select: searchName, value: searchText}"></search-group>
            <el-checkbox v-model="hasChild" type="primary" plain class="search-containChild">{{$t('userManage.includeChild')}}</el-checkbox>
            <label class="search-label align-right imr-0">{{$t('common.queryItem')}}:</label>
          </div>

          <el-table :data="tableData" class="v-table" border @sort-change="sortChange" @header-dragend="saveHeaderDragWidth" size="mini" :height="tableHeight" ref="multipleTable" v-loading="tableLoading" :element-loading-text="tableLoadingText">
            <el-table-column type="selection" width="55" :selectable="selectableHandle"></el-table-column>
            <el-table-column sortable="custom" prop="UserName" :label="$t('accountManage.account')" :width="TW($t('accountManage.account'), 120)" show-overflow-tooltip>
              <div class="i-link" style="overflow:hidden;" slot-scope="{row}" @click.stop="rowClick(row)" :title="`${row.UserName}--id:${row.UserID}`">{{ row.UserName }}</div>
            </el-table-column>
            <el-table-column sortable="custom" prop="RealName" :label="$t('accountManage.userName')" :width="TW($t('accountManage.userName'), 110)" show-overflow-tooltip></el-table-column>
            <el-table-column sortable="custom" prop="UserTypeName" :label="$t('accountManage.userType')" :width="TW($t('accountManage.userType'), 90)">
              <p slot-scope="{row}">{{$t('dict')[row.UserTypeName] || row.UserTypeName}}</p>
            </el-table-column>
            <el-table-column sortable="custom" prop="StartTime" :label="$t('accountManage.startTime')" :width="TW($t('accountManage.startTime'), 110)">
              <template slot-scope="{row}">{{miment(row.StartTime).format('YYYY-MM-DD')}}</template>
            </el-table-column>
            <el-table-column sortable="custom" prop="EndTime" :label="$t('accountManage.endTime')" :width="TW($t('accountManage.endTime'), 120)">
              <template slot-scope="{row}">{{miment(row.EndTime).format('YYYY-MM-DD')}}</template>
            </el-table-column>
            <el-table-column prop="Remark" :label="$t('accountManage.remark')" :width="TW($t('accountManage.remark'), 120)" show-overflow-tooltip></el-table-column>
            <el-table-column sortable="custom" prop="FullPathName" :label="$t('accountManage.fullPath')" :minWidth="TW($t('accountManage.fullPath'), 240)" show-overflow-tooltip></el-table-column>
            <el-table-column label="" :resizable="false" width="100px">
              <template slot-scope="{row}">
                <i-drop-down trigger="click">
                  <span slot="active" class="i-link">{{$t('common.operate')}}<i class="el-icon-caret-bottom" style="margin-left:0.5em;"></i></span>
                  <i-drop-down-option :label="$t('common.modify')" value="0" @click="rowClick(row)" v-if="loginInfo.allFuns.includes(5032)"></i-drop-down-option>
                  <i-drop-down-option :label="$t('common.delete')" value="4" @click="deleteHandle([row])" v-if="loginInfo.allFuns.includes(5033)"></i-drop-down-option>
                </i-drop-down>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            class="v-pager"
            background
            :page-size="pageSize"
            :page-sizes="pageSizes"
            :pager-count="11"
            :current-page="pageIndex"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotalCount"
            @current-change="pageCurrentChange"
            @size-change="pageSizeChange"
            popper-class="custom">
          </el-pagination>

          <transition name="slide-top" @before-enter="isAnimating=true" @before-leave="isAnimating=true" @after-enter="isAnimating=false" @after-leave="isAnimating=false">
            <router-view @refresh="refreshHandle"></router-view>
          </transition>

          <detail-user ref="detail" @refresh="refreshHandle"></detail-user>
          <!-- <div class="page__detail">
            测试页面
          </div> -->
        </template>
      </div>
    </div>
  <!-- </main-wrapper> -->
</template>

<script>
import { VclCode } from 'vue-content-loading'
import SearchGroup from './components/search-group'
import IDropDown from '@/base/i-dropdown/i-dropdown'
import IDropDownOption from '@/base/i-dropdown/i-dropdown-option'
import mixin from './mixin'
import * as API from '@/api/user'
import {Filter, Op} from '@/api/config'
import DetailUser from './detail/detail-user'
import { saveBlob } from '@/common/utils'
import miment from 'miment'

export default {
  name: 'manageUser',
  data() {
    return {
      miment,
      options: [
        { label: '账号', value: 'UserName', Op: Op.Contains },
        { label: '名称', value: 'RealName ', Op: Op.Contains },
        { label: '备注', value: 'U.Remark', Op: Op.Contains }
      ],
      isAnimating: false // 是否正在动画
    }
  },
  components: {
    IDropDown,
    IDropDownOption,
    SearchGroup,
    VclCode,
    DetailUser
  },
  mixins: [
    mixin
  ],
  watch: {
    '$i18n.locale': 'getOptions'
  },
  created() {
    this.getOptions()
    this.searchName = this.options[0].value
    this.pageIndex = 1
    this.queryHandle()
  },
  methods: {
    click() {
      this.pageIndex = 1
      this.queryHandle()
    },
    getOptions() {
      this.options = [
        { label: this.$t('accountManage.account'), value: 'UserName', Op: Op.Contains },
        { label: this.$t('accountManage.userName'), value: 'RealName ', Op: Op.Contains },
        { label: this.$t('accountManage.remark'), value: 'U.Remark', Op: Op.Contains }
      ]
    },
    rowClick(row) {
      //this.$router.push(`/manage/user/detail?id=${row.UserID}`)
      this.$refs.detail.open(row.UserID)
    },
    selectableHandle() {
      return true
      //return row.UserID != this.loginInfo.UserID
    },
    addHandle() {
      // this.$router.push('/manage/user/detail')
      this.$refs.detail.open()
    },
    deleteHandle(rows) {
      if (!(rows instanceof Array)) {
        rows = null
      }
      const targetRows = rows || this.$refs.multipleTable.selection

      if (targetRows.length <= 0) {
        this.$alert(this.$t('common.emptySelectedTip'), '', {
          type: 'error'
        })
        return
      }

      let msg0 = this.$t('common.deleteTip')
      this.$confirm(msg0, this.$t('common.warn'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {

        this.deleteButtonLoading = true
        API.Delete(targetRows.map(k => k.UserID)).then(() => {
          // 从本地删除
          // targetRows.forEach(item => {
          //   deleteFromArray(this.tableData, row => {
          //     return row == item
          //   })
          // })

          this.$message({
            type: 'success',
            message: this.$t('common.deleteSuccessed'),
            showClose: true
          });

          if ((targetRows.length < this.pageSize)
            && (targetRows.length < this.tableData.length)) {
            this.pageIndex = this.pageIndex > 1 ? this.pageIndex - 1 : 1
          }
          this.pageIndex = 1
          this.queryHandle()

        }).catch(err => {
          this.print('error', err.message)
        })

      }).catch(() => {

      });
    },
    queryHandle() {
      const promise = new Promise((resolve, reject) => {
        const filters = []

        this.searchText && this.searchText.length > 0
          && (filters.push(new Filter(this.searchName, this.searchText, this.searchOption)))
        this.tableLoading = true
        API.Query(this.pageIndex, this.pageSize, this.sortKey, this.sortDirect, filters, this.currentHoldId, this.hasChild).then(ret => {
          //console.log(ret)
          this.tableData = ret.data.list.slice()
          this.pageTotalCount = ret.data.total
          this.tableLoading = false
          resolve()
        }).catch(err => {
          this.print('error', err.message)
          this.tableLoading = false
          reject(err)
        })
      })
      return promise
    },
    exportHandle() {
      this.exportButtonLoading = true
      API.Export(this.currentHoldId, this.hasChild).then(ret => {
        this.exportButtonLoading = false
        saveBlob(ret.data, `${this.$t('userDetail.name')}.xlsx`)
      })
    },
    refreshHandle() {
      this.pageIndex = 1
      this.queryHandle()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-wrapper {
  display: flex;
  align-items: stretch;
  padding: 0 30px;
  background-color: #000416;
  .v-nav {
    width: 250px;
    flex-shrink: 0;
    overflow-y: auto;
    position: relative;
  }
  .v-body {
    width: 0;
    flex-grow: 1;
    padding: 0 45px;
    border-radius: 8px;
    box-sizing: border-box;
  }
}
</style>
